
import {PropType, defineComponent, ref} from 'vue';
import TeamUser, {TeamUsers} from '@/models/TeamUser';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import FormCollectionSelect from '@/components/common/form/FormCollectionSelect.vue';
import Role from '@/library/enumerations/Role';
import Team from '@/models/Team';
import onboardingStore from '@/store/onboarding';

export default defineComponent({
    components: {
        BtnResource,
        FormCollectionSelect,
    },
    props: {
        baseTeam: {
            type: Object as PropType<Team>,
            required: true,
        },
    },
    setup(props) {
        const teamUsers = new TeamUsers();
        teamUsers.endpoint = `teams/${props.baseTeam.id}/team-users`;

        return {
            team: ref(props.baseTeam),
            pitcher: ref(new TeamUser()),
            teamUsers: ref(teamUsers),
        };
    },
    created() {
        this.team.resetStatus();
    },
    methods: {
        async assignPitcher() {
            if (this.pitcher.roles) {
                this.pitcher.roles.push(Role.PITCHER);
            }

            this.team.sync = [];

            if (this.pitcher.id) {
                this.team.sync.push({
                    id: this.pitcher.id,
                    roles: this.pitcher.roles,
                });
            }

            await this.team.update();

            if (this.team.error) {
                return;
            }

            this.toDashboard();
        },
        toDashboard() {
            onboardingStore.showOnboarding.value = true;

            this.$router.push({name: 'home'});
        },
    },
});
