import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "mt-4 text-black-50 text-center" }
const _hoisted_3 = { class: "d-grid mt-12" }
const _hoisted_4 = { class: "d-grid mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormCollectionSelect = _resolveComponent("FormCollectionSelect")!
  const _component_BtnResource = _resolveComponent("BtnResource")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('teams.assignPitcher.title')), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('teams.assignPitcher.body')), 1),
    _createElementVNode("form", {
      class: "mt-12",
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.assignPitcher && _ctx.assignPitcher(...args)), ["prevent"]))
    }, [
      _createVNode(_component_FormCollectionSelect, {
        modelValue: _ctx.pitcher,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pitcher) = $event)),
        class: "mb-12",
        collection: _ctx.teamUsers,
        "error-key": "teamUsers.sync.0.id",
        errors: _ctx.team.errors,
        "fetch-on-mounted": "",
        label: _ctx.$t('teams.assignPitcher.selectUser'),
        "label-prop": "user.email"
      }, null, 8, ["modelValue", "collection", "errors", "label"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BtnResource, {
          disabled: !_ctx.pitcher.id,
          resource: _ctx.team
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('actions.letsGo')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "resource"])
      ])
    ], 32),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        class: "btn",
        "data-test-id": "btn-skip",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toDashboard && _ctx.toDashboard(...args)))
      }, _toDisplayString(_ctx.$t('actions.skip')), 1)
    ])
  ]))
}